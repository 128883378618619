.form-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  .full-width-element {
    width: calc(100% - 24px);
    outline: none;
    border-radius: 5px;
    border: 1px solid $lightgrey;
    padding: 12px;
    font-size: 1rem;
  }

  .form-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    button {
      width: 90px;
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $teal;
      }
    }
  }

  .two-column-grid {
    display: grid;
    grid-template-columns: 1fr 55px;
    align-items: center;
    grid-gap: 15px;
  }

  .three-icon-grid {
    display: grid;
    grid-template-columns: 20px 1fr 55px;
    align-items: center;
    grid-gap: 15px;
  }

  i {
    font-weight: 900;
    font-size: 1.5em;
    color: $teal;
  }

  button {
    background-color: transparent;
    border: 1px transparent solid;
    .fa-plus-square {
      font-size: 4em;
      transition: all 0.5s ease-in-out;

      &:hover {
        cursor: pointer;
        color: $darkteal;
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.form-element-group {
  label {
    font-weight: 900;
  }

  input {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
