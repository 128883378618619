.list-headers-1 {
  @include list-item();
  @include list-header();
  grid-template-columns: 1fr 20px;
}

.dynamic-row-1 {
  @include list-item();
  @include data-item();
  grid-template-columns: 1fr 20px;
}

.list-headers-2 {
  @include list-item();
  @include list-header();
  grid-template-columns: 1fr 1fr 20px;
}

.dynamic-row-2 {
  @include list-item();
  @include data-item();
  grid-template-columns: 1fr 1fr 20px;
}

.list-headers-4 {
  @include list-item();
  @include list-header();
  grid-template-columns: 20% 20% 20% 20% 20px;
}

.dynamic-row-4 {
  @include list-item();
  grid-template-columns: 20% 20% 20% 20% 20px;
}

.list-headers-5 {
  @include list-item();
  @include list-header();
  grid-template-columns: 18% 18% 18% 18% 18% 10%;
}

.dynamic-row-5 {
  @include list-item();
  grid-template-columns: 18% 18% 18% 18% 18% 10%;
}

.list-headers-6 {
  @include list-item();
  @include list-header();
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
}

.dynamic-row-6 {
  @include list-item();
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
}

.list-headers-7 {
  @include list-item();
  @include list-header();
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
}

.dynamic-row-7 {
  @include list-item();
  @include data-item();
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
}

.list-headers-8 {
  @include list-item();
  @include list-header();
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
}

.dynamic-row-8 {
  @include list-item();
  @include data-item();
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
}

.list-headers-9 {
  @include list-item();
  @include list-header();
  grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
  padding-right: 30px;
}

.dynamic-row-9 {
  @include list-item();
  @include data-item();
  grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
  padding-right: 30px;
}

.list-headers-10 {
  @include list-item();
  @include list-header();
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  padding-right: 90px;
}

.dynamic-row-10 {
  @include list-item();
  @include data-item();
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  padding-right: 90px;
}
