.card {
  background-color: white;
  color: #2a2828;
  position: relative;
  padding: 20px;
  margin: 10px 20px 42px 0px;
  border-radius: 8px;

  .metadata {
    color: white;
    position: absolute;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 180px;
    background: rgb(103, 37, 221);
    background: linear-gradient(
      145deg,
      rgba(103, 37, 221, 1) 0%,
      rgba(32, 0, 97, 1) 100%
    );

    .title {
      padding: 20px;
      font-size: 1.1em;
      font-family: "Ubuntu", sans-serif;
    }
  }
}
