@mixin data-item {
  font-size: 0.9em;
  word-wrap: break-word;
}

@mixin list-header {
  font-weight: 900;
  color: $teal;
  border-bottom: 2px solid $teal;

  &:hover {
    border-bottom: 2px solid transparent;
  }
}

@mixin list-item {
  display: grid;
  grid-gap: 21px;
  transition: all 0.5s ease-in-out;
  border-bottom: 2px solid transparent;
  padding: 15px 0px;
  align-items: center;

  &:hover {
    border-bottom: 2px solid $teal;
  }

  .actions {
    button {
      background-color: transparent;
      border: transparent;

      i {
        font-size: 2em;
        color: $warning;
        transition: all 0.5s ease-in-out;

        &:hover {
          cursor: pointer;
          color: $darkwarning;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }
}
