.list-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;

  a {
    color: $teal;
    text-decoration: none;
    font-weight: 900;
  }

  .endpoint-list-item {
    @include list-item();
    grid-template-columns: 42px 1fr 2fr;
  }

  .data-list-item {
    @include list-item();
    grid-template-columns: 15px 1fr 2fr 1.5fr;
  }

  .single-record-list-item {
    @include list-item();
    grid-template-columns: 1fr 42px;
  }

  .title {
    font-weight: 900;
  }
}
