.auth-card {
  width: 362px !important;
  height: 600px !important;

  .metadata {
    height: 100px !important;

    .small-green-line {
      margin-left: 20px !important;
    }
  }

  .auth-form {
    margin-top: 110px;

    .form-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .input-elements {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 35px;

        .form-element-group {
          display: grid;
          grid-template-columns: 1fr;

          label {
            font-size: 0.8em;
            font-weight: 700;
            color: $charcoal;
          }
        }
      }

      .form-buttons-wrapper {
        position: absolute;
        bottom: 0;
        text-align: center;
        align-self: center;
        margin-bottom: 15px;
      }
    }
  }
}
