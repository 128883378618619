.project-cards-wrapper {
  display: flex;
}

.project-card {
  width: 250px;
  height: 400px;

  img {
    width: 100%;
  }
}
