.link-btn {
  button {
    border: none;
    background-color: transparent;
    font-size: 1.1em;
    padding-bottom: 15px;
    border-bottom: 2px solid transparent;
    transition: all 0.5s;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.btn-primary {
  button {
    color: $teal;
    &:hover {
      border-bottom: 2px solid $teal;
    }
  }
}

.btn-cancel {
  button {
    color: $lightgrey;
    &:hover {
      border-bottom: 2px solid $lightgrey;
    }
  }
}
