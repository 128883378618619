.small-green-line {
  width: 30px;
  height: 3px;
  border-radius: 10px;
  background-color: $teal;
}

.spacer30 {
  height: 30px;
}

.spacer40 {
  height: 40px;
}

.spacer50 {
  height: 50px;
}

.spacer80 {
  height: 80px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.center-text {
  text-align: center;
}
