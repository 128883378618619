body {
  font-family: "Raleway", sans-serif;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1d1651;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Ubuntu", sans-serif;
}

.app-wrapper {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  padding: 100px;
}
