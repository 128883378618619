.project-detail-header {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 21px;
  margin-bottom: 42px;
  color: white !important;
  text-decoration: none;

  img {
    width: 100%;
  }
}
